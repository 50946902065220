import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, getGoogleoAuthUrlDataApi } from "../utils/Constants";


export const getGoogleDriveoAuthUrl = createAsyncThunk("getGoogleDriveoAuthUrl", async () => {
    try {
        const token = localStorage.getItem("token");

        const config = {
            headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        };
        const response = await axios.get(ApiBaseUrl + getGoogleoAuthUrlDataApi, config);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
});


const getGoogleDriveoAuthUrlSlice = createSlice({
    name: "getGoogleDriveoAuthUrlReducer",

    initialState: {
        isLoading: false,
        data: null,
    },
    reducers: {
        clearGetGoogleDriveoAuthUrlSliceData: (state) => {
            state.data = null;
            state.isAuthenticated = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGoogleDriveoAuthUrl.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getGoogleDriveoAuthUrl.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(getGoogleDriveoAuthUrl.rejected, (state) => {
                state.isError = false;
            })
    },
});

export const { clearGetGoogleDriveoAuthUrlSliceData } = getGoogleDriveoAuthUrlSlice.actions;
export default getGoogleDriveoAuthUrlSlice.reducer;

import React, { useState } from "react";
import ai_img from "../../assets/img/img-logo.svg";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { forgotPasswordApi, ApiBaseUrl } from '../../utils/Constants'

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmitBtnClick = () => {
        if (!email || email === '') {
            alert('Please enter your email.')
            return
        }
        setLoading(true)
        axios
            .post(`${ApiBaseUrl}${forgotPasswordApi}`, { email: email })
            .then((res) => {
                setLoading(false);
                if (res.data && res.data.status === 1) {
                    alert(res.data.message);
                    setEmail("");
                } else {
                    alert(res.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                alert("Something went wrong!");
            });
    };
    console.log(`${ApiBaseUrl}${forgotPasswordApi}`)

    return (
        <>
            <div className="inner">
                <div className="sec_bg">
                    <div className="logotype">
                        <img src={ai_img} alt="ai_img" />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </p>
                    </div>

                </div>
                <div className="signup_bg">
                    <div className="text_btn">
                        <h4>Don't have an account?</h4>
                        <Button
                            onClick={() => {
                                navigate("/SignUp")
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            Sign Up
                        </Button>
                    </div>

                    <div className="signup_form">
                        <Form>
                            <h3>Forgot Password</h3>

                            <Form.Group className="mb-3 form_group">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    className="createInput"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ height: "44px" }}
                                />
                            </Form.Group>


                            <div className="next_btn">
                                <Button
                                    disabled={loading}
                                    onClick={onSubmitBtnClick}
                                    style={{
                                        cursor: loading ? "not-allowed" : "pointer",
                                        opacity: loading ? 0.4 : 1
                                    }}>
                                    Submit
                                </Button>
                            </div>

                        </Form>
                    </div>

                    <p>
                        Protected by reCAPTCHA and subject to the Rhombus <br />
                        <b style={{ color: '#0F0F0F' }}>Privacy Policy</b> and <b style={{ color: '#0F0F0F' }}>Terms of Service.</b>
                    </p>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;

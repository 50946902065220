/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import useDrivePicker from "react-google-drive-picker";

import { ApiBaseUrl, pdfUploadApi, UiBaseUrl } from "../../utils/Constants";
import drive from "../../assets/img/drive.svg";
import localDevice from "../../assets/img/local-device.svg";
import oneDrive from "../../assets/img/onedriveSmall.svg";
import { getProfile } from "../../redux/GetProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { getGoogleDriveAccessToken } from "../../redux/GetgoogleDriveAccessTokenSlice";
import { getOneDriveAccessToken } from "../../redux/GetOneDriveAccessTokenSlice";

const PdfFileUpload = ({
  projectId,
  setShowOptionList,
  onPdfUploadSuccess,
}) => {

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openPicker, authResponse] = useDrivePicker();
  const [fileFrom, setFileFrom] = useState("local");
  const [googleDriveToken, setGoogleDriveToken] = useState(null);
  const [oneDriveToken, setoneDriveToken] = useState(null);

  const profileResponse = useSelector((state) => state.getProfileReducer.data);
  const googleDriveAccessTokenResponse = useSelector((state) => state.getGoogleDriveAccessTokenReducer.data);
  const oneDriveAccessTokenResponse = useSelector((state) => state.getOneDriveAccessTokenReducer.data);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (profileResponse != null && profileResponse.status === 1) {
      if (profileResponse && profileResponse.data && profileResponse.data.isGoogleDriveSync === 1) {
        dispatch(getGoogleDriveAccessToken());
      }

      if (profileResponse && profileResponse.data && profileResponse.data.isOneDriveSync === 1) {
        dispatch(getOneDriveAccessToken());
      }
    }
  }, [profileResponse, dispatch]);

  useEffect(() => {
    if (googleDriveAccessTokenResponse != null && googleDriveAccessTokenResponse.status === 1) {
      setGoogleDriveToken(googleDriveAccessTokenResponse.data.access_token);
    }
  }, [googleDriveAccessTokenResponse]);

  useEffect(() => {
    if (oneDriveAccessTokenResponse != null && oneDriveAccessTokenResponse.status === 1) {
      setoneDriveToken(oneDriveAccessTokenResponse.data.access_token);
    }
  }, [oneDriveAccessTokenResponse]);


  // Google Drive File Picker
  const handleGoogleDrivePicker = () => {
    setFileFrom("google-drive");
    setSelectedFile(null);
    let googleDriveOption = {
      clientId: "1068841346594-v3bin4n70kcirk94pnkt9ssrtv9ikh4a.apps.googleusercontent.com",
      developerKey: "AIzaSyBJ2Ak89rQ4oA1Imz-jGlutYR8K5vBlGGg",
      viewId: "PDFS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,

      callbackFunction: async (data) => {
        if (data.action === "cancel") {
          return null;
        }
        if (
          data &&
          data.docs &&
          Array.isArray(data.docs) &&
          data.docs.length > 0
        ) {
          setSelectedFile(data.docs[0]);
        }
      },
    }
    if (googleDriveToken && profileResponse && profileResponse?.data?.isGoogleDriveSync === 1) {
      googleDriveOption['token'] = googleDriveToken;
    }
    openPicker(googleDriveOption);
  };
  const downloadGoogleDriveFile = async (fileId, authResponse) => {
    const url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authResponse && authResponse.access_token ? authResponse.access_token : googleDriveToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      const blob = await response.blob();
      return blob;
    } catch (err) {
      alert("Error downloading file:", err);
      return;
    }
  };


  // One Drive File Picker
  const handleOneDrivePicker = () => {
    if (!window.OneDrive) {
      console.error("OneDrive Picker script not loaded yet.");
      return;
    }
    setFileFrom("one-drive");
    setSelectedFile(null);

    const options = {
      clientId: "2e089a72-8c4d-44e1-a06f-f85cf687449c", // Replace with your Azure App Client ID
      // clientId: "35717bd64b58f342ac-123d-4506-bcc4-d9",
      action: "download", // or "share"
      multiSelect: false,
      openInNewWindow: true,
      advanced: {
        redirectUri: `${UiBaseUrl}/TaskAi`,
        filter: ".pdf",
      },
      success: (files) => {
        console.log("Selected Files:", files);
        if (
          files &&
          files.value &&
          Array.isArray(files.value) &&
          files.value.length > 0
        ) {
          setSelectedFile(files.value[0]);
        }
      },
      cancel: () => console.log("User clicked cancel/close button"),
      error: (error) => {
        console.error("OneDrive Picker Error:", error);
        alert("Error picking OneDrive files. Check console for details.");
      },
    };
    if(oneDriveToken && profileResponse && profileResponse?.data?.isOneDriveSync === 1){
      options["accessToken"] = oneDriveToken
    };

    try {
      window.OneDrive.open(options);
    } catch (err) {
      console.error("Unexpected OneDrive Picker Error:", err);
    }
  };
  const downloadOneDriveFile = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }

      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error downloading file:", error);
      return null;
    }
  };


  // Upload file to server
  const handleFileUpload = async (event) => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    } else if (projectId == "") {
      alert("Please select a project first.");
      return;
    }
    setLoading(true);


    try {
      console.log("1111111111111111111111111111111111111: inside handleFileUpload : ", authResponse, googleDriveToken, oneDriveToken)
      let fileToUpload = selectedFile;
      if (fileFrom === "google-drive") {
        console.log(`Processing file: ${selectedFile.name}`);
        const fileBlob = await downloadGoogleDriveFile(
          selectedFile.id,
          authResponse
        );
        if (!fileBlob) {
          alert("Failed to download file from Google Drive.");
          setLoading(false);
          return;
        }

        // Convert Blob to a File object (server requires a file)
        fileToUpload = new File([fileBlob], selectedFile.name, { type: "application/pdf" });
      }

      if (fileFrom === "one-drive") {
        console.log(`Processing OneDrive file: ${selectedFile.name}`);
        const fileBlob = await downloadOneDriveFile(selectedFile["@microsoft.graph.downloadUrl"]);

        if (!fileBlob) {
          alert("Failed to download file from One Drive.");
          setLoading(false);
          return;
        }

        // Convert Blob to a File object (server requires a file)
        fileToUpload = new File([fileBlob], selectedFile.name, { type: "application/pdf" });
      }

      // const file = event.target.files[0];
      const formData = new FormData();
      formData.append("projectId", projectId);
      formData.append("pdf", fileToUpload);

      axios
        .post(ApiBaseUrl + pdfUploadApi, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          alert(
            "File uploaded successfully!, Now you can start start the question from the pdf"
          );

          if (response.data && onPdfUploadSuccess) {
            onPdfUploadSuccess(response.data);
          }
          setSelectedFile(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          console.log(response);
          setShowOptionList(false);
        })
        .catch((error) => {
          console.log(error);
          // alert("Error uploading file. Please try again later.");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false)
      alert("Error uploading file. Please try again later.");
      return null;
    }
  };


  // Hndle close
  const handleclose = () => {
    setShowOptionList(false);
  };

  console.log("    selectes slll aall files", selectedFile, "from", fileFrom, "authResponse: ", authResponse, googleDriveToken);

  return (
    <div className="upload-pdf">

      <div className="upload-buttons">
        <button
          title="Upload From Device"
          disabled={loading}
          className="local-device-icon picker-btn"
          onClick={() => {
            fileInputRef.current && fileInputRef.current.click();
            setFileFrom("local");
            setSelectedFile(null);
          }}
        >
          <img
            src={localDevice}
            alt="local-device-icon icon"
            className="icon"
          />
          <input
            type="file"
            onChange={(event) => {
              setSelectedFile(event.target.files[0]);
            }}
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".pdf,.doc,.docx"
          />
        </button>

        <button
          title="Upload From Google Drive"
          disabled={loading}
          className="google-drive-icon picker-btn"
          onClick={handleGoogleDrivePicker}
        >
          <img src={drive} alt="google-drive-icon icon" className="icon" />
        </button>

        <button
          title="Upload From One Drive"
          disabled={loading}
          className="one-drive-icon picker-btn"
          onClick={handleOneDrivePicker}
        >
          <img src={oneDrive} alt="one-drive-icon icon" className="icon" />
        </button>
      </div>

      {selectedFile && <div className="selected-file" title={selectedFile.name}>
        <h5 className="file-name">{selectedFile.name.length > 25 ? selectedFile.name.slice(0, 25) + "..." : selectedFile.name}</h5>
      </div>}


      <div className="action-btn">
        <button onClick={handleFileUpload} disabled={!selectedFile || loading}>
          {loading ? "Uploading..." : "Upload"}
        </button>
        {setShowOptionList && <button disabled={loading} onClick={handleclose}>
          Close
        </button>}
      </div>
    </div>
  );
};

export default PdfFileUpload;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, getGoogleoAuthAccessTokenDataApi } from "../utils/Constants";


export const getGoogleDriveAccessToken = createAsyncThunk("getGoogleDriveAccessToken", async () => {
  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
    };
    const response = await axios.get(ApiBaseUrl + getGoogleoAuthAccessTokenDataApi, config);
    console.log("response: access token",response)
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

const GetGoogleDriveAccessTokenSlice = createSlice({
  name: "getGoogleDriveAccessTokenReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearGoogleDriveAccessTokenData: (state) => {
      state.data = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGoogleDriveAccessToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGoogleDriveAccessToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getGoogleDriveAccessToken.rejected, (state) => {
        state.isError = false;
      });
  },
});

export const { clearGoogleDriveAccessTokenData } = GetGoogleDriveAccessTokenSlice.actions;
export default GetGoogleDriveAccessTokenSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl, getGoogleoAuthAccessTokenRevokeApi } from "../utils/Constants";


export const revokeGoogleDrivePermissionSlice = createAsyncThunk("revokeGoogleDrivePermissionSlice", async () => {
    try {
        const token = localStorage.getItem("token");

        const config = {
            headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        };
        const response = await axios.get(ApiBaseUrl + getGoogleoAuthAccessTokenRevokeApi, config);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
});


const revokeGoogleDrivePermissionSliceSlice = createSlice({
    name: "revokeGoogleDrivePermissionSliceReducer",

    initialState: {
        isLoading: false,
        data: null,
    },
    reducers: {
        clearrevokeGoogleDrivePermissionSliceSliceData: (state) => {
            state.data = null;
            state.isAuthenticated = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revokeGoogleDrivePermissionSlice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(revokeGoogleDrivePermissionSlice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(revokeGoogleDrivePermissionSlice.rejected, (state) => {
                state.isError = false;
            })
    },
});

export const { clearrevokeGoogleDrivePermissionSliceSliceData } = revokeGoogleDrivePermissionSliceSlice.actions;
export default revokeGoogleDrivePermissionSliceSlice.reducer;

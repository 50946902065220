import { useEffect, useState } from "react";
import FlexSearch from "flexsearch";
import crossIcon from "../../assets/img/Icon.svg";

import { useDispatch, useSelector } from "react-redux";
import { getQuestinDetail } from "../../redux/GetQuestionDetailsSlice";
import Test from "./Test";

// Styles
const drawerStyles = (isOpen) => ({
  position: "fixed",
  // bottom: "100px",
  right: isOpen ? "0" : "-100%",
  top: "0",
  width: "50vw",
  height: "100vh",
  background: "white",
  boxShadow: "2px 0 5px rgba(0, 0, 0, 0.3)",
  transition: "right 0.4s ease-in-out",
  zIndex: 999,
  overflowY: "auto",
});

const closeButtonStyles = {
  position: "absolute",
  top: "5px",
  right: "5px",
  color: "white",
  border: "none",
  cursor: "pointer",
  padding: "6px 12px",
  fontSize: "15px",
  zIndex: 1009,
};

const PdfDrawer = ({ pdfUrl, isOpen, onClose, questionId }) => {
  const dispatch = useDispatch();
  const getQuestinDetailResponse = useSelector((state) => state.getQuestinDetail.data);

  const [keywords, setKeywords] = useState(null);

  useEffect(() => {
    if (questionId) {
      dispatch(getQuestinDetail({ questionId, timestamp: Date.now() }));
    }
  }, [questionId, dispatch]);

  useEffect(() => {
    if (isOpen && getQuestinDetailResponse && getQuestinDetailResponse?.status === 1) {
      extractKeywords(getQuestinDetailResponse.data);
    }
  }, [getQuestinDetailResponse]);


  async function formatKeyword(matchingRawText, matchingText = "I don't know.") {
    if (!matchingRawText || typeof matchingRawText !== "string" || !matchingText) return [];

    let foundKeys = matchingRawText
      .split("\n")
      .map(line => line.trim())
      .filter(line => line.length > 0)
      .filter(line => !/^\d+$/.test(line))
      .map(line => line.replace(/\s+/g, ' '));

      // console.log("22222222222222222222222 : found keys : ", foundKeys)

    const matchingTextArray = matchingText
      .split("\n")
      .map(line => line.trim())
      .filter(line => line.length > 0);

      // console.log("33333333333333333333333333 : matchingTextArray : ", matchingTextArray)

    // Initialize FlexSearch index
    const index = new FlexSearch.Index({ tokenize: "full" });

    // Add matchingTextArray to the index
    matchingTextArray.forEach((text, i) => index.add(i, text));

    // Filter foundKeys using FlexSearch
    let matchedKeywords = foundKeys.filter(keyword => {
      const result = index.search(keyword, { limit: 1 });
      return result.length > 0; // Keep only matching keywords
    });

    // Step 1: Remove keywords that have 1 or 2 words
    matchedKeywords = matchedKeywords.filter(keyword => keyword.split(" ").length > 2);

    // Step 2: Keep only the longest keywords and remove contained shorter ones
    matchedKeywords = matchedKeywords.filter((keyword, index, array) => {
      return !array.some(otherKeyword =>
        otherKeyword.length > keyword.length && otherKeyword.includes(keyword)
      );
    });
    return matchedKeywords
    
  }



  const extractKeywords = async (message) => {
    if (!message || typeof message !== "object") return;
    let keys = []
    const { matchingRawText, matchingText } = message;
    if (matchingRawText && typeof matchingRawText == "string" && matchingText) {
      keys = await formatKeyword(matchingRawText, matchingText);
    } else {
      const showingAnswer = matchingText || "I don't know.";
      keys = [showingAnswer];
    }
    setKeywords(keys);
  };


  // console.log("8888888888 : 1111111111111111: 2222222222222222222: keywords : ", keywords, getQuestinDetailResponse)

  return (
    keywords && getQuestinDetailResponse && pdfUrl &&
    <div style={drawerStyles(isOpen)}>
      <button style={closeButtonStyles} onClick={onClose}>
        <img src={crossIcon} alt="Close" />
      </button>
      <Test keywords={keywords} pdfUrl={pdfUrl} />
    </div >
  )
};

export default PdfDrawer;

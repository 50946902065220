import React, { useEffect, useState } from "react";
import drive_2 from "../../assets/img/drive_2.svg";
import gmail_2 from "../../assets/img/gmail_2.svg";
import onedriveSmall from "../../assets/img/onedriveSmall.svg";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Header/Header";
import { clearGetProfileData, getProfile } from "../../redux/GetProfileSlice";
import { clearGetGoogleDriveoAuthUrlSliceData, getGoogleDriveoAuthUrl } from "../../redux/GetGoogleoAuthUrlSlice";
import { revokeGoogleDrivePermissionSlice } from "../../redux/RevokeGoogleDrivePermissionSlice";
import { ApiBaseUrl } from "../../utils/Constants";
import { revokeOneDrivePermissionSlice } from "../../redux/RevokeOneDrivePermissionSlice";
import { clearOneDriveAccessTokenData } from "../../redux/GetOneDriveAccessTokenSlice";

const Integrations = () => {
  const dispatch = useDispatch();
  const googleDriveAccessTokenUrlResponse = useSelector(state => state.getGoogleDriveoAuthUrlReducer.data)
  const profileResponse = useSelector((state) => state.getProfileReducer.data);

  const [profile, setProfile] = useState(null);
  const [googleDriveoAuthUrl, setGoogleDriveoAuthUrl] = useState(null);
  const [isGoogleDriveOn, setIsGoogleDriveOn] = useState(false);
  const [isOneDriveOn, setIsOneDriveOn] = useState(false);


  useEffect(() => {
    dispatch(getProfile())
  }, [dispatch])

  useEffect(() => {
    if (!profileResponse || profileResponse.status !== 1) return;

    const { data } = profileResponse;
    setProfile(data);

    setIsGoogleDriveOn(data?.isGoogleDriveSync === 1);
    setIsOneDriveOn(data?.isOneDriveSync === 1);

    if (data?.isGoogleDriveSync === 0) {
      dispatch(getGoogleDriveoAuthUrl());
    }
  }, [profileResponse, dispatch]);

  useEffect(() => {
    if (googleDriveAccessTokenUrlResponse && googleDriveAccessTokenUrlResponse.status === 1) {
      setGoogleDriveoAuthUrl(googleDriveAccessTokenUrlResponse?.data)
    }
  }, [googleDriveAccessTokenUrlResponse])


  // Google Drive
  const handleGoogleDriveChange = () => {
    if (isGoogleDriveOn) {
      dispatch(clearGetGoogleDriveoAuthUrlSliceData())
      dispatch(clearGetProfileData())
      setIsGoogleDriveOn(false);
      dispatch(revokeGoogleDrivePermissionSlice());
      dispatch(getGoogleDriveoAuthUrl())
    } else {
      googleDriveoAuthUrl && handleGoogleDriveLogin(googleDriveoAuthUrl);
    }
  };

  const handleGoogleDriveLogin = (googleDriveoAuthUrl) => {
    const width = 500, height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 1;

    const popup = window.open(
      googleDriveoAuthUrl,
      "googleOAuthPopup",
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,noopener,noreferrer`
    );

    if (!popup) {
      alert("Popup blocked! Please allow popups.");
      return;
    }

    const handleOAuthSuccess = (event) => {
      if (event.origin !== window.location.origin) return;
      if (event.data === "google-drive-sync-success") {
        dispatch(getProfile());
        setTimeout(() => {
          popup.close();
        }, 3000)
        window.removeEventListener("message", handleOAuthSuccess);
      }
    };

    window.addEventListener("message", handleOAuthSuccess);
  }


  // One Drive
  const handleOneDriveToggleChange = async () => {
    if (isOneDriveOn) {
      dispatch(clearGetProfileData())
      dispatch(clearOneDriveAccessTokenData())
      setIsOneDriveOn(false)
      dispatch(revokeOneDrivePermissionSlice());
    } else {
      await handleOneDriveLogin()
    }
  };

  const handleOneDriveLogin = async () => {
    const clientId = "2e089a72-8c4d-44e1-a06f-f85cf687449c";
    const redirectUri = encodeURIComponent(`${ApiBaseUrl}/v1/user/onedrive/auth/callback`);
    const scopes = encodeURIComponent("offline_access Files.ReadWrite");
    const codeVerifier = "my_custom_secure_code_verifier_12345";
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    const userId = profile._id;
    const state = encodeURIComponent(JSON.stringify({ user_id: userId, codeVerifier }));

    // Authorization URL with PKCE
    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
      client_id=${clientId}
      &response_type=code
      &redirect_uri=${redirectUri}
      &scope=${scopes}
      &code_challenge=${codeChallenge}
      &code_challenge_method=S256
      &state=${state}`.replace(/\s+/g, "");
    const width = 500, height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 1;

    // Open popup window
    const popup = window.open(
      authUrl,
      "oneDriveAuthPopup",
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
    );

    if (!popup) {
      alert("Popup blocked! Please allow popups in your browser.");
    }

    const handleOAuthSuccess = (event) => {
      if (event.origin !== window.location.origin) return;
      if (event.data === "one-drive-sync-success") {
        dispatch(getProfile());
        setTimeout(() => {
          popup.close();
        }, 3000)
        window.removeEventListener("message", handleOAuthSuccess);
      }
    };

    window.addEventListener("message", handleOAuthSuccess);
  };

  const generateCodeChallenge = async (codeVerifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await crypto.subtle.digest("SHA-256", data);
    return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  };




  return (
    <>
      {profile && <div className="sidebar_mar">
        <Header />

        <div className="about_content">
          <h2>Integrations</h2>
          <div className="google_card">
            <div className="google_style">
              <h4>Google</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
            <div className="switch_img">
              <img src={drive_2} alt="drive_2" className="drive_2" />
              <img src={gmail_2} alt="gmail_2" className="gmail_2" />
              <div
                className={`switch ${isGoogleDriveOn ? "on" : "off"}`}
                onClick={handleGoogleDriveChange}
              >
                <div className="toggle"></div>
              </div>
            </div>
          </div>

          <div className="google_card">
            <div className="google_style">
              <h4>OneDrive</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
            <div className="switch_img">
              <img src={onedriveSmall} alt="drive_2" className="drive_2" />
              <div
                className={`switch ${isOneDriveOn ? "on" : "off"}`}
                onClick={handleOneDriveToggleChange}
              >
                <div className="toggle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default Integrations;

import React from "react";
import DOMPurify from "dompurify";

const formatTextToHTML = (text) => {
  return text
    .split(/\n|(?=[A-Z][a-z]+:\s)/) // Split on new lines OR when a key (e.g., "Professionalism:") appears
    .map((line) => {
      const trimmedLine = line.trim();

      // Key-Value pair (e.g., "Professionalism: Accredited designers...")
      if (/^[A-Z][a-z]+:\s/.test(trimmedLine)) {
        const [title, ...description] = trimmedLine.split(":");
        return `<p><strong>${title.trim()}:</strong> ${description.join(":").trim()}</p>\n`;
      }

      // Numbered list (e.g., "1) Follow best practices.")
      if (/^\d+\)\s/.test(trimmedLine)) {
        return `<p style="margin-left: 20px;">${trimmedLine}</p>`; // Add left margin for alignment
      }

      // Bullet points (- or •)
      if (/^[-•]\s/.test(trimmedLine)) {
        return `<p style="margin-left: 20px;">${trimmedLine}</p>`;
      }

      return `<p>${trimmedLine}</p>`; // Preserve normal text formatting
    })
    .join(""); // Join without extra spaces
};

const CodeOfConduct = ({ text }) => {
  const formattedHTML = DOMPurify.sanitize(formatTextToHTML(text)); // Sanitize before injecting

  return (
    <div
      dangerouslySetInnerHTML={{ __html: formattedHTML }} // Inject safely
    />
  );
};

export default CodeOfConduct;

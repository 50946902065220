import React from "react";
import "./AuthSuccess.scss"; // Import the SCSS file

const AuthSuccess = () => {
    return (
        <div className="auth-success">
            <header className="auth-header">
                <h1>Authentication Successful</h1>
            </header>
            <div className="auth-content">
                <h2>Thank You!</h2>
                <p>Your authentication was successful.Please wait, you will be redirected to the application.</p>
            </div>
        </div>
    );
};

export default AuthSuccess;

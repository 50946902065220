import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useEffect, useState } from 'react';

const HighlightKeywordsExample = ({ pdfUrl, keywords = [] }) => {
    const [isDocumentLoaded, setDocumentLoaded] = useState(false);
    const handleDocumentLoad = () => setDocumentLoaded(true);

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { toolbarPluginInstance } = defaultLayoutPluginInstance;
    const { searchPluginInstance } = toolbarPluginInstance;
    const { highlight } = searchPluginInstance;
    

    useEffect(() => {
        if (isDocumentLoaded && keywords.length > 0) {
            highlight(keywords);

        }
    }, [isDocumentLoaded, keywords]);

    return (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
            <Viewer
                fileUrl={pdfUrl}
                plugins={[defaultLayoutPluginInstance]}
                onDocumentLoad={handleDocumentLoad}
            />
        </Worker>
    );
};

export default HighlightKeywordsExample;

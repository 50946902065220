// Sidebar.js
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import ai_img from "../../assets/img/img-logo.svg";

// import dashboard_icon from "../../assets/img/dashboard_icon.svg";
// import ai_icon_inactive from "../../assets/img/ai_icon_sidebar_inactive.svg";
// import Integrations from "../../assets/img/Integrations.svg";
// import box from "../../assets/img/project.svg";

import dashboard_icon_inactive from "../../assets/img/dashboard_icon_inactive.svg";
import dashboard_icon from "../../assets/img/dashboard_icon_color.svg";
import ai_icon_inactive from "../../assets/img/ai_icon_sidebar_inactive.svg";
import ai_icon from "../../assets/img/ai_icon_color.svg";

import Integrations from "../../assets/img/Integrations_color.svg";
import Integrations_inactive from "../../assets/img/Integrations_inactive.svg";

import box from "../../assets/img/box_color.svg";
import box_inactive from "../../assets/img/project_inactive.svg";

import document from "../../assets/img/document_color.svg";
import document_inactive from "../../assets/img/document_inactive.svg";
import logout from "../../assets/img/logout.svg";
import { googleLogout } from "@react-oauth/google";
import { clearDataSignUp } from "../../redux/signupSlice";
import { clearDataOtp } from "../../redux/OtpSlice";
import { clearUser } from "../../redux/userSlice";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const logoutClick = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    googleLogout();
    clearDataSignUp();
    clearDataOtp();
    clearUser();
    
    setTimeout(() => {
      window.location.replace("/");
    }, 500)
  };

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? 'active-link' : 'span_color';
  };

  return (
    <div className="my-3">
      <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
        <div className="top-section">
          <img className="logo" src={ai_img} alt="ai_img" />
          {/* <h1 className="logo">
            <img src={ai_img} alt="ai_img" />
            Logotype
          </h1> */}
          {/* <div className="bars" onClick={toggleSidebar}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div> */}
        </div>
        <nav className="nav-menu">
          <ul>
            <li>
              <NavLink to="/Dashboard" className={isActive('/Dashboard')}>
                {/* isActive('/Dashboard') === 'active-link' ? dashboard_icon : dashboard_icon_inactive */}
                <img src={isActive('/Dashboard') === 'active-link' ? dashboard_icon : dashboard_icon_inactive} alt="dashboard_icon" />{" "}
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/TaskAi" className={isActive('/TaskAi')}>
                <img src={isActive('/TaskAi') === 'active-link' ? ai_icon : ai_icon_inactive} alt="ai_icon" /> <span>Task Ai</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/Integrations" className={isActive('/Integrations')}>
                <img src={isActive('/Integrations') === 'active-link' ? Integrations : Integrations_inactive} alt="Integrations" />{" "}
                <span>Integrations</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/Project" className={isActive('/Project')}>
                <img src={isActive('/Project') === 'active-link' ? box : box_inactive} alt="box" /> <span>Projects</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/Documents" className={isActive('/Documents')}>
                <img src={isActive('/Documents') === 'active-link' ? document : document_inactive} alt="Documents" /> <span>Documents</span>
              </NavLink>
            </li>
            <li className="logout">
              <div onClick={() => logoutClick()} className="logout_div">
                <img src={logout} alt="logout" /> <span>Logout</span>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;

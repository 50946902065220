import React, { useEffect, useState } from "react";
import ai_img from "../../assets/img/img-logo.svg";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate, useSearchParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { ApiBaseUrl, resetPasswordApi } from '../../utils/Constants'
import { VisibilityTwoTone, VisibilityOffTwoTone } from "@mui/icons-material";
import ReactPasswordChecklist from "react-password-checklist";

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!token) {
            navigate('/SignIn')
        }
    }, [token, navigate])

    const onResetPasswordClick = () => {
        if (!password || password === '' || !confirmPassword || confirmPassword === '') {
            alert("Please enter required fields: New Password, Confirm Password")
            return
        }
        if (password !== confirmPassword) {
            alert("New Password and Confirm Password must be equal")
            return
        }
        setLoading(true)
        axios
            .post(`${ApiBaseUrl}${resetPasswordApi}`, { newPassword: password, token: token })
            .then((res) => {
                setLoading(false);
                if (res.data && res.data.status === 1) {
                    alert(res.data.message);
                    navigate('/SignIn', { replace: true })
                } else {
                    alert(res.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                alert("Something went wrong!");
            });
    };
    console.log(`${ApiBaseUrl}${resetPasswordApi}`, "token: ", token)

    return (
        <>
            <div className="inner">
                <div className="sec_bg">
                    <div className="logotype">
                        <img src={ai_img} alt="ai_img" />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </p>
                    </div>

                </div>
                <div className="signup_bg">
                    <div className="text_btn">
                        <h4>Don't have an account?</h4>
                        <Button
                            onClick={() => {
                                navigate("/SignUp")
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            Sign Up
                        </Button>
                    </div>

                    <div className="signup_form">
                        <Form>
                            <h3>Reset Password</h3>
                            <Form.Group className="mb-3 form_group">
                                <Form.Group
                                    className="mb-4 form-contain"
                                    style={{ marginTop: 16 }}
                                    controlId="formBasicPassword"
                                >
                                    <Form.Label className="pass-label">New Password</Form.Label>
                                    <div className="inline">
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            className="createInput"
                                            placeholder="Password"
                                            name="Password"
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="passwod_btn"
                                            onClick={() => {
                                                setShowPassword(!showPassword);
                                            }}
                                        >
                                            {showPassword ? (
                                                <VisibilityTwoTone />
                                            ) : (
                                                <VisibilityOffTwoTone />
                                            )}
                                        </button>
                                    </div>
                                </Form.Group>

                                <Form.Group
                                    className="mb-4 form-contain"
                                    style={{ marginTop: 16 }}
                                    controlId="formBasicPassword"
                                >
                                    <Form.Label className="pass-label">Confirm Password</Form.Label>
                                    <div className="inline">
                                        <Form.Control
                                            type={showConfirmPassword ? "text" : "password"}
                                            className="createInput"
                                            placeholder="Confirm Password"
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            onChange={(e) => {
                                                setConfirmPassword(e.target.value);
                                            }}
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="passwod_btn"
                                            onClick={() => {
                                                setShowConfirmPassword(!showConfirmPassword);
                                            }}
                                        >
                                            {showConfirmPassword ? (
                                                <VisibilityTwoTone />
                                            ) : (
                                                <VisibilityOffTwoTone />
                                            )}
                                        </button>
                                    </div>
                                </Form.Group>
                            </Form.Group>
                            {password.length > 0 && confirmPassword.length > 0 && (
                                <ReactPasswordChecklist
                                    rules={[
                                        "minLength",
                                        "specialChar",
                                        "number",
                                        "capital",
                                        "match",
                                    ]}
                                    minLength={5}
                                    value={password}
                                    valueAgain={confirmPassword}
                                    onChange={(isValid) => { }}
                                    style={{ marginTop: 10 }}
                                />
                            )}


                            <div className="next_btn">
                                <Button
                                    disabled={loading}
                                    onClick={onResetPasswordClick}
                                    style={{
                                        cursor: loading ? "not-allowed" : "pointer",
                                        opacity: loading ? 0.4 : 1
                                    }}>
                                    Submit
                                </Button>
                            </div>

                        </Form>
                    </div>

                    <p>
                        Protected by reCAPTCHA and subject to the Rhombus <br />
                        <b style={{ color: '#0F0F0F' }}>Privacy Policy</b> and <b style={{ color: '#0F0F0F' }}>Terms of Service.</b>
                    </p>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;

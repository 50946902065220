
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ApiBaseUrl,
  getQuestinDetailApi,
} from "../utils/Constants";

export const getQuestinDetail = createAsyncThunk(
  "getQuestinDetail",
  async (payload) => {
    try {
      const token = localStorage.getItem("token");
      const questionId = payload.questionId;
      const config = {
        headers: { Authorization: token },
      };

      const response = await axios.get(
        ApiBaseUrl + getQuestinDetailApi + questionId,
        config
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const GetQuestinDetailSlice = createSlice({
  name: "QuestinDetailReducer",

  initialState: {
    isLoading: false,
    data: null,
  },
  reducers: {
    clearQuestinDetailData: (state) => {
      state.data = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestinDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestinDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getQuestinDetail.rejected, (state) => {
        state.isError = false;
      });
  },
});

export const { clearQuestinDetailData } = GetQuestinDetailSlice.actions;
export default GetQuestinDetailSlice.reducer;

import React, { useEffect, useRef, useState } from "react";
import send_icon from "../../assets/img/send_icon.svg";
import add_circle from "../../assets/img/add_circle.svg";
import ai_icon_new from "../../assets/img/ai_icon_color.svg";
import copy_icon from "../../assets/img/copy_icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChatHistory } from "../../redux/GetChatHistorySlice";
import { askQuestion } from "../../redux/AskQuestionSlice";
import { askQuestionFromPdf } from "../../redux/AskQuestionFromPdfSlice";
import { getProjectList } from "../../redux/GetProjectListSlice";
import PdfFileUpload from "../PdfFileUpload/PdfFileUpload";
import { uploadFile, resetUploadState } from "../../redux/fileUploadSlice";
import { ApiBaseUrl } from "../../utils/Constants";
import PdfViewer from "../PdfViewer/PdfViewerNew";
import Header from "../Header/Header";
import { Button } from "react-bootstrap";
import { clearQuestinDetailData } from "../../redux/GetQuestionDetailsSlice";
import FormatResponse from "./FormatResponse";

function YourAi() {
  const chatContainerRef = useRef(null);
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [projectId, setProjectId] = useState("");
  const [chatData, setChatData] = useState([]);
  const [question, setQuestion] = useState("");
  const [question1, setQuestion1] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [showOptionList, setShowOptionList] = useState(false);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentProjectFileName, setCurrentProjectFileName] = useState(null);
  const [isProdjectWithPdf, setIsProdjectWithPdf] = useState(false);
  const [pdfListinCurrentProject, setPdfListinCurrentProject] = useState([]);
  const [selectedPdfId, setSelectedPdfId] = useState("all");
  const [isPdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataForPdfHighlight, setDataForPdfHighlight] = useState("");
  const [currentPdfContext, setCurrentPdfContext] = useState(null);
  const [checkModalType, setCheckModalType] = useState("ChatGPT");

  // console.log("currentProject", currentProject);
  console.log("location", location);
  // console.log("currentPdfContext", currentPdfContext);

  // getting state from document page

  useEffect(() => {
    if (selectedPdfId == undefined && currentProject?.ProjectChat.length > 0) {
      // setSelectedPdfId(currentProject?.ProjectChat.length > 0 && currentProject?.ProjectChat[0]?._id);
      closePdfViewer()
      setSelectedPdfId(currentProject?.ProjectChat[0]?._id);
    }
  }, [currentProject]);

  const handlePdfLinkClick = (pdfUrl, responseText, questionId, dataFromDocs) => {
    setCurrentPdfContext({ pdfUrl, responseText, questionId, dataFromDocs });
    setPdfViewerOpen(true);
  };

  useEffect(() => { }, [currentPdfContext]);

  const closePdfViewer = () => {
    dispatch(clearQuestinDetailData())
    setPdfViewerOpen(false);
    setCurrentPdfContext(null);
  };

  const { uploading, uploadSuccess, uploadError, uploadedFile } = useSelector(
    (state) => state.fileUpload
  );

  const chatResponse = useSelector((state) => state.chatHistoryReducer.data);
  const questionResponse = useSelector(
    (state) => state.askQuestionReducer.data
  );
  // console.log("questionResponse", questionResponse);
  const questionResponseFromPdf = useSelector(
    (state) => state.askQuestionFromPdfReducer.data
  );
  // console.log("questionResponseFromPdf", questionResponseFromPdf);

  const projectResponse = useSelector(
    (state) => state.getProjectListReducer.data
  );

  // console.log("location", location);
  useEffect(() => {
    if (location.state != null) {
      setProjectId(location.state.id);
    }
    dispatch(getProjectList());
  }, []);

  useEffect(() => {
    // console.log("Project Response ===> ", projectResponse);
    if (projectResponse != null && projectResponse.status == 1) {
      setProjectList(projectResponse.data);
      if (projectId == "" && projectResponse.data.length > 0) {
        setProjectId(projectResponse.data[0]._id);
      }
    }
  }, [projectResponse]);

  useEffect(() => {
    if (projectId != "") {
      const payload = {
        id: projectId,
      };
      dispatch(getChatHistory(payload));
    }
  }, [projectId]);

  useEffect(() => {
    // console.log("History Data ===> ", chatResponse);
    if (chatResponse != null && chatResponse.status == 1) {
      setChatData(chatResponse.data);
    } else {
      setLoading(false);
    }
  }, [chatResponse]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    // console.log("Chat Data =====>", chatData);
  }, [chatData]);

  const onAskQuestion = () => {
    setQuestion("");
    const payload = {
      projectId: projectId,
      question: question,
    };
    setLoading(true);
    closePdfViewer()
    dispatch(askQuestion(payload));
  };

  useEffect(() => {
    // console.log("Question Response ===> ", questionResponse);
    if (questionResponse != null && questionResponse.status == 1) {
      setChatData([questionResponse.data, ...chatData]);
      setQuestion("");

      setLoading(false);
    }
  }, [questionResponse]);
  console.log("currentProject", currentProject);

  // useEffect(()=>{
  //   if()
  // },[currentProject])

  const onAskQuestionFromPdf = () => {
    setQuestion("");

    if (!selectedPdfId && checkModalType == "ChatGPT") {
      alert("Please select a PDF first.");
      return;
    }

    const payload = {
      projectId: projectId,
      projectChatId: selectedPdfId,
      userQuery: question,
    };
    closePdfViewer()
    setLoading(true);
    dispatch(askQuestionFromPdf(payload));
  };

  console.log("selectedPdfId", selectedPdfId);
  useEffect(() => {
    // console.log("Question Response from PDF ===> ", questionResponseFromPdf);
    if (questionResponseFromPdf && questionResponseFromPdf.status == 1) {
      setChatData([questionResponseFromPdf.data, ...chatData]);
      setQuestion("");
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [questionResponseFromPdf]);

  useEffect(() => {
    if (uploadSuccess) {
      alert("File uploaded successfully!");
      dispatch(resetUploadState());
      showOptionList(false);
    }

    if (uploadError) {
      alert("Failed to upload file.");
      dispatch(resetUploadState());
    }
  }, [uploadSuccess, uploadError]);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    if (currentProject?.ProjectChat) {
      const pdfList = currentProject?.ProjectChat.map((item) => ({
        id: item._id,
        pdfName: item.chatName,
      }));

      setPdfListinCurrentProject(pdfList);
    }
  }, [currentProject]);

  useEffect(() => {
    if (location.state === null) {
      if (pdfListinCurrentProject.length > 0) {
        setSelectedPdfId(
          pdfListinCurrentProject[pdfListinCurrentProject.length - 1].id
        );
      }
    }
  }, [pdfListinCurrentProject]);

  //   useEffect(() => {
  //     if (location.state != null) {
  //         const { projectId, documentId } = location.state;
  //         setProjectId(projectId);
  //         setSelectedPdfId(documentId);
  //     } else if (pdfListinCurrentProject.length === 1) {
  //         setSelectedPdfId(pdfListinCurrentProject[0]?.id);
  //     }
  //     dispatch(getProjectList());
  // }, [location.state, pdfListinCurrentProject, dispatch]);

  // useEffect(() => {
  //   if (pdfListinCurrentProject.length > 0) {
  //       setSelectedPdfId(
  //           pdfListinCurrentProject[pdfListinCurrentProject.length - 1]?.id
  //       );
  //   } else if (pdfListinCurrentProject.length === 1) {
  //       setSelectedPdfId(pdfListinCurrentProject[0]?.id);
  //   }
  // }, [pdfListinCurrentProject]);

  // console.log("location",location)

  // console.log("selectedPdfId",selectedPdfId)
  // console.log("pdfListinCurrentProject",pdfListinCurrentProject[pdfListinCurrentProject.length - 1].id)
  // console.log("selectedPdfId",selectedPdfId)

  const getCurrentProjectFromId = (prodjcetId) => {
    const result = projectList.find((project) => project._id === prodjcetId);
    return result;
  };

  useEffect(() => {
    if (projectId) {
      setCurrentProject(getCurrentProjectFromId(projectId));
    }
  }, [projectId, projectList]);

  useEffect(() => {
    if (currentProject) {
      setCurrentProjectFileName(
        currentProject?.ProjectChat[currentProject?.ProjectChat.length - 1]
          ?.chatName
      );
    }
  }, [currentProject]);

  const onProjectSelect = (selectedProjectId) => {
    setProjectId(selectedProjectId);
    closePdfViewer()
  };

  useEffect(() => {
    if (projectId !== "") {
      const payload = {
        id: projectId,
      };
      dispatch(getChatHistory(payload));
    }
  }, [projectId, dispatch]);

  useEffect(() => {
    if (chatResponse && chatResponse.status === 1) {
      setChatData(chatResponse.data);
    } else {
      setLoading(false);
    }
  }, [chatResponse]);

  useEffect(() => {
    if (currentProjectFileName) {
      setIsProdjectWithPdf(true);
    } else {
      setIsProdjectWithPdf(false);
    }
  }, [currentProjectFileName]);

  useEffect(() => {
    if (location.state != null) {
      const { projectId, documentId } = location.state;
      setProjectId(projectId);
      setSelectedPdfId(documentId);
    }
    dispatch(getProjectList());
  }, [location.state, dispatch]);

  const handlePdfUploadSuccess = (newPdf) => {
    setLoading(false);
    dispatch(getProjectList());
    getCurrentProjectFromId(projectId);
    const updatedPdfList = [
      ...pdfListinCurrentProject,
      { id: newPdf._id, pdfName: newPdf.chatName },
    ];
    setPdfListinCurrentProject(updatedPdfList);
  };
  // console.log("chatData", chatData);

  const handleChangeModelType = (e) => {
    setCheckModalType(e.target.value);
    setIsProdjectWithPdf(false);
  };

  console.log("pdfListinCurrentProject", pdfListinCurrentProject);
  return (
    <div className="sidebar_mar">
      <Header />

      <div className="project_btn_style">
        <h2>Task Ai</h2>
      </div>

      <div className="sidebar_det">
        <div className="flex gap-5 itemcenter">
          <select
            className="project-select"
            value={checkModalType}
            onChange={handleChangeModelType}
          >
            <option value="ChatGPT">ChatGPT</option>
            <option value="Llama3">Llama3</option>
          </select>
        </div>
        <div className="flex gap-5 itemcenter">
          {/* <label>Select Project: </label> */}
          <select
            className="project-select"
            value={projectId}
            onChange={(e) => onProjectSelect(e.target.value)}
          >
            {projectList.map((project) => (
              <option key={project._id} value={project._id}>
                {project.projectName}
              </option>
            ))}
          </select>
          {isProdjectWithPdf && checkModalType === "ChatGPT" && (
            <div>
              {/* <label>Select PDF: </label> */}
              <select
                className="project-select"
                value={selectedPdfId}
                onChange={(e) => setSelectedPdfId(e.target.value)}
              >
                <option value={"all"}>All Files</option>
                {pdfListinCurrentProject.map((pdf) => (
                  <option key={pdf.id} value={pdf.id}>
                    {pdf.pdfName}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
      <div className="chat_main">
        <div className="scroll_content" ref={chatContainerRef}>
          {chatData.length > 0 &&
            chatData
              .slice()
              .reverse()
              .map((message, index) => {
                

                let dataFromDocs = message?.matchingText || "I don't know.";
                const dataForPdf = message?.matchingText || "I don't know.";
                
                const matchingChat = currentProject?.ProjectChat.find(
                  (chat) => chat._id === message.projectChatId
                );
                const pdfName = matchingChat?.chatName || null;
                const pdfLink = ApiBaseUrl + "/pdf/" + matchingChat?.pdf || "#";
                // console.log("message", message);
                const messageId = message._id;

                return (
                  <div className="content" key={index}>
                    <div className="conversation">
                      <div className="message">
                        <div className="message_content">
                          <div className="user_chat">
                            <p>{message.request}</p>
                          </div>
                        </div>
                        <div className="message-meta">
                          <img
                            src={ai_icon_new}
                            alt="ai_icon_new"
                            className="ai_icon_new"
                          />
                          <span className="ai_contents">
                            <div style={{ whiteSpace: "pre-wrap", lineHeight: '24px'}}>
                              {/* {dataFromDocs} */}
                              {/* <div dangerouslySetInnerHTML={{ __html: dataFromDocs }} /> */}
                              < FormatResponse  text={dataFromDocs} />

                            </div>
                            {pdfName && (
                              <>
                                {isProdjectWithPdf && (
                                  <div className="chat-pdf-name">
                                    <span
                                      onClick={() =>
                                        handlePdfLinkClick(
                                          pdfLink,
                                          dataForPdf,
                                          messageId,
                                          dataFromDocs
                                        )
                                      }
                                    >
                                      {pdfName}
                                      {/* <img src={copy_icon} pdfName alt="pdf" /> */}
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </span>
                          <div
                            className="day_img"
                            onClick={() => copyToClipboard(message.response)}
                          >
                            <img src={copy_icon} alt="copy_icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

          {loading && (
            <div className="content">
              <div className="conversation">
                <div className="message">
                  <div className="message_content">
                    <div className="user_chat">
                      <p>{question1}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isPdfViewerOpen && (
            // <PdfViewer
            //   pdfUrl={currentPdfContext.pdfUrl}
            //   textData={currentPdfContext.responseText}
            //   onClose={closePdfViewer}
            // />

            <PdfViewer
              pdfUrl={currentPdfContext.pdfUrl}
              questionId= {currentPdfContext.questionId}
              onClose={closePdfViewer}
              isOpen={isPdfViewerOpen}
            />
          )}
        </div>
        {loading && <div className="loading">loading...</div>}
        <div className="input-area">
          <div className="pdf_file">
            {checkModalType === "ChatGPT" && (
              <img
                src={add_circle}
                alt="add_circle"
                className="add_circle"
                onClick={() => setShowOptionList(!showOptionList)}
                style={{ cursor: "pointer" }}
              />
            )}
            {showOptionList && (
              <>
                <div className="document-optionlist">
                  <div className="document-option-item">
                  <h4 className="upload-title">Upload Document</h4>
                    <PdfFileUpload
                      projectId={projectId}
                      setShowOptionList={setShowOptionList}
                      onPdfUploadSuccess={handlePdfUploadSuccess}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <input
            type="text"
            placeholder="Message for Your AI"
            value={question}
            onChange={(v) => {
              setQuestion(v.target.value);
              setQuestion1(v.target.value);
            }}
            disabled={loading}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                isProdjectWithPdf ? onAskQuestionFromPdf() : onAskQuestion();
              }
            }}
          />
          <img
            src={send_icon}
            style={{ cursor: "pointer" }}
            alt="send_icon"
            className="send_icon"
            onClick={() =>
              isProdjectWithPdf ? onAskQuestionFromPdf() : onAskQuestion()
            }
          />
        </div>
      </div>
    </div>
  );
}

export default YourAi;

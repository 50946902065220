import React, { useEffect, useState } from "react";
import notification from "../../assets/img/notification.svg";
import user_icon from "../../assets/img/user_icon.svg";
import down_icon from "../../assets/img/down_icon.svg";
import { Button } from "react-bootstrap";
import next_icon from "../../assets/img/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjectList } from "../../redux/GetProjectListSlice";
import { getDocumentList } from "../../redux/GetDocumentListSlice";
import Header from "../Header/Header";

const Project = () => {

  const navigation = useNavigate();

  const dispatch = useDispatch();

  const [projectList, setProjectList] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [id, setID] = useState("");

  const projectResponse = useSelector(
    (state) => state.getProjectListReducer.data
  );
  const documentResponse = useSelector(
    (state) => state.getDocumentListReducer.data
  );

  useEffect(() => {
    dispatch(getProjectList());
    dispatch(getDocumentList());
  }, []);

  useEffect(() => {
    console.log("Project Response ===> ", projectResponse);
    if (projectResponse != null && projectResponse.status == 1) {
      setProjectList(projectResponse.data);
    }
  }, [projectResponse]);

  useEffect(() => {
    console.log("Document Response ===> ", documentResponse);
    if (documentResponse != null && documentResponse.status == 1) {
      setDocumentsList(documentResponse.data);
    }
  }, [documentResponse]);

  const documentsByProject = projectList.map((project) => {
    const relatedDocuments = documentsList.filter(
      (doc) => doc.projectId === project._id
    );
    return {
      ...project,
      documents: relatedDocuments,
    };
  });

  const handleSearchClick = (projectId) => {
    navigation("/TaskAi", {
      state: {
        projectId,
      },
    });
  };
  
  console.log("111111111111", documentsList)

  return (
    <div className="sidebar_mar">
      <Header />
      <div className="project_btn_style">
        <h2>Projects</h2>
        <Button type="button" onClick={() => navigation("/CreateProject")}>
          Add New
        </Button>
      </div>
      {documentsByProject.length > 0 &&
        documentsByProject.map((project, index) => (
          <div
            key={index}
            className="magic_content_btn"
            style={{ cursor: "pointer" }}
            onClick={() => handleSearchClick(project._id)}
          >
            <div className="label_input_flex">
              <h4>{project.projectName}</h4>
              <p>
                {project.streetAddress}, {project.state}, {project.country}
              </p>
            </div>

            <div className="active_btn_content">
              <div className="active_column">
                <button className="active_button">Active</button>
                <p>{project.documents.length} Documents indexed</p>
              </div>
              <img src={next_icon} alt="next_icon" />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Project;
